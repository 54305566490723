<template>
  <div>
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="result"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="true"
      :addable="true"
      :dialogAdder="true"
      addButtTitle="Add Attribute"
      linePrefix="radius_request"
    ></lineFieldsArray>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";
export default {
  components: { lineFieldsArray },
  computed: {
    fields() {
      let fields = {
        attribute: {
          type: "combobox",
          associatedOption: this.attrsArray,
          inputFieldFormatter: "attributeInput",
          name: "Attribute",
          source: this.source,
          fieldAttrInput: { class: "required" },
          sort: false,
          color(valueLine) {
            console.log("color lined called");
            return valueLine.color;
          },
        },

        type: {
          type: "radio",
          inputFieldFormatter: "getValueTypeFields",
          colAttrs: { style: "max-width: 260px;min-width: 260px;" },
          option: { static: "Static", dynamic: "Dynamic" },
          fieldAttrInput: {},

          default: "static",
        },
        content: {
          inputFieldFormatter: "renderInputValue",
          application: this.application,
          radiusDynamicPars: this.radiusDynamicPars,

          applicationValue: this.applicationValue,
          type: "text",
          fieldAttrInput: { class: "required validateCases" },
          name: "Value",
        },

        // fix: {
        //   inputFieldFormatter: "fixSuppress",
        //   colAttrs: {
        //     cols: 1,
        //     style: "max-width: 70px;min-width: 70px;",
        //     //  class: "mt-1"
        //   },

        //   name: "Suppress",
        //   default: "0",
        // },
        fix: lineButtons.fix,
        mandatory: {
          type: "hidden",
          colAttrs: { class: "d-none" },
        },
        remove: { ...lineButtons.remove },
        source: {
          type: "hidden",
          colAttrs: { class: "d-none" },
          default: this.source,
        },

        atRanId: {
          type: "hidden",
          colAttrs: { class: "d-none" },
        },
      };
      if (this.isTemplate) {
        fields.fix = {
          type: "hidden",
          colAttrs: { class: "d-none" },
        };
        fields.mandatory = lineButtons.mandatory;
        // fields.mandatory = {
        //   inputFieldFormatter: "lineCheckBoxContainer",
        //   type: "checkbox",
        //   colAttrs: {
        //     cols: 1,
        //     style: "max-width: 120px;min-width: 120px;",
        //   },

        //   name: "Mandatory",
        //   default: "0",
        // };
      }

      this.$set(fields.remove, "inputFieldFormatter", "diameterTrash");
      this.$set(fields.remove, "isTemplate", this.isTemplate);
      this.$set(fields.remove, "source", this.source);
      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [];
        for (const [key, valueArray] of Object.entries(this.value)) {
          valueArray.forEach((value) => {
            let type;
            if (
              typeof value.content == "string" &&
              value.content.indexOf("EVAL:") > -1
            ) {
              type = "dynamic";
            } else {
              type = "static";
            }
            let readOnly = false;
            if (value.fix == "2") {
              readOnly = true;
            }
            pairArray.push({
              attribute: key,
              ...value,
              type: type,
              readOnly: readOnly,
            });
          });
        }
        return pairArray;
      },
      // set(newValue) {
      //     console.log("new value",newValue);
      // }
    },
  },
  props: {
    attrsArray: {},
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    application: {},
    source: {},
    radiusDynamicPars: {},
    isTemplate: { type: Boolean, default: false },
    applicationValue: {},
    result: {},
  },
  methods: {
    getFieldAttrRow(valueLine) {
      //console.log("color called", valueLine);
      let attrs = {};
      if (typeof valueLine.color != "undefined") {
        attrs = { style: "color: " + valueLine.color };
      }
      return attrs;
    },
    checkInput(newValueArray) {
      //console.log(newValueArray);
      let request = {};
      newValueArray.forEach((line) => {
        let content = line.content;
        if (line.type == "dynamic" && line.content.indexOf("EVAL:") == -1) {
          content = "EVAL:";
        }
        if (line.type == "static" && line.content.indexOf("EVAL:") > -1) {
          content = "";
        }
        let insertedLine = {
          content: content,
          fix: line.fix,
          mandatory: line.mandatory,
          source: line.source,
        };
        if (typeof request[line.attribute] != "undefined") {
          request[line.attribute].push(insertedLine);
        } else {
          request[line.attribute] = [insertedLine];
        }
      });
      this.$emit("input", request);
    },
  },
};
</script>